import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { keys, keyBy, toArray, omit, get, map, pick } from 'lodash';

import { api, apiV2 } from 'api';
import { getChannelsByKeys } from './utils';
import { combineActions } from '../../utils/combineActions';

const initialState = {
  playlists: {},
  favorites: {},
  playlistsKeys: [],
  selectedPlaylist: {
    channels: {},
    categories: [],
    channelsKeys: {},
  },
  error: {
    status: null,
    errorKey: null,
  },
  isLoading: false,
};

const actions = {
  fetchPlaylists: createAsyncThunk(
    'playlists/fetchPlaylists',
    () => apiV2.get('playlists'),
  ),
  fetchFavorites: createAsyncThunk(
    'playlists/fetchFavorites',
    () => apiV2.get('playlists/favorites'),
  ),
  outdatedFetchFullPlaylist: createAsyncThunk(
    'playlists/outdatedFetchFullPlaylist',
    uuid => api.get(`playlists/${uuid}`)
  ),
  outdatedEditPlaylist: createAsyncThunk(
    'playlists/outdatedEditPlaylist',
    ({ uuid, data }) => api.post(`playlists/${uuid}`, data),
  ),
  outdatedDeletePlaylist: createAsyncThunk(
    'playlists/outdatedDeletePlaylist',
    ({ uuid }) => api.delete(`playlists/${uuid}`)
  ),
  outdatedSyncPlaylist: createAsyncThunk(
    'playlists/outdatedSyncPlaylist',
    ({ uuid: playlistUUID }) => api.post(`playlists/${playlistUUID}/merge`, { forced: true }),
  ),
  outdatedHideChannel: createAsyncThunk(
    'playlists/outdatedHideChannel',
    ({ playlistUUID, channelUUID }) => api.post(`playlists/${playlistUUID}/channels/${channelUUID}/hide`),
  ),
  outdatedSortChannels: createAsyncThunk(
    'playlists/outdatedSortChannels',
    ({ playlistUUID, oldIndex, newIndex }) =>
      api.post(`playlists/${playlistUUID}/channels/sort`,
        { 'old_index': oldIndex, 'new_index': newIndex }
      ),
  ),
  outdatedUpdateFavorite: createAsyncThunk(
    'playlists/outdatedUpdateFavorite',
    ({ playlistUUID, channelUUID, favStatus }) =>
      api.put(`playlists/${playlistUUID}/channels/${channelUUID}`, { favorite: favStatus })
  ),
  fetchPlaylistData: createAsyncThunk(
    'playlists/fetchPlaylistData',
    uuid => apiV2.get(`playlists/${uuid}`)
  ),
  fetchChannels: createAsyncThunk(
    'playlists/fetchChannels',
    (uuid, { rejectWithValue }) => apiV2.get(`playlists/${uuid}/channels`).catch(e => rejectWithValue(e))
  ),
  fetchCategories: createAsyncThunk(
    'playlists/fetchCategories',
    uuid => apiV2.get(`playlists/${uuid}/categories`)
  ),
  createPlaylist: createAsyncThunk(
    'playlists/createPlaylist',
    ({ data }, { rejectWithValue }) =>
      apiV2.post('playlists', data).catch(e => rejectWithValue(e)),
  ),
  editPlaylist: createAsyncThunk(
    'playlists/editPlaylist',
    ({ uuid, data, deviceUUID }) => apiV2.post(`playlists/${uuid}`, data, {
      headers: {
        'Device-UUID': deviceUUID,
      },
    }),
  ),
  changeVisibility: createAsyncThunk(
    'playlists/changeVisibility',
    ({ uuid, data, deviceUUID }) => apiV2.post(`playlists/${uuid}/visibility`, data, {
      headers: {
        'Device-UUID': deviceUUID,
      },
    }),
  ),
  syncPlaylist: createAsyncThunk(
    'playlists/syncPlaylist',
    async ({ uuid: playlistUUID }, { rejectWithValue }) => {
      try {
        return await apiV2.post(`playlists/${playlistUUID}/merge`, { forced: true });
      } catch (e) {
        return rejectWithValue(e);
      }
    }
  ),
  deletePlaylist: createAsyncThunk(
    'playlists/deletePlaylist',
    ({ uuid }) => apiV2.delete(`playlists/${uuid}`)
  ),
  hideChannel: createAsyncThunk(
    'playlists/hideChannel',
    ({ playlistUUID, channelUUID, hidden }) =>
      apiV2.post(`playlists/${playlistUUID}/channels/${channelUUID}/hide`, { hidden }),
  ),
  updateFavorite: createAsyncThunk(
    'playlists/updateFavorite',
    ({ playlistUUID, channelUUID, favorite }) =>
      apiV2.post(`playlists/${playlistUUID}/channels/${channelUUID}/favorite`, { favorite })
  ),
  reorderChannels: createAsyncThunk(
    'playlists/reorderChannels',
    ({ playlistUUID, oldIndex, newIndex }) =>
      apiV2.post(`playlists/${playlistUUID}/channels/reorder`,
        { 'old_index': oldIndex, 'new_index': newIndex }
      ),
  ),
  reorderFavorites: createAsyncThunk(
    'playlists/reorderFavorites',
    ({ oldIndex, newIndex }) =>
      api.post(`playlists/channels/sort`,
        { 'old_index': oldIndex, 'new_index': newIndex }
      ),
  ),
  hideCategory: createAsyncThunk(
    'playlists/hideCategory',
    ({ playlistUUID, groupId }) => apiV2.post(`playlists/${playlistUUID}/categories/${groupId}/hide`),
  ),
  toggleCategoryVisibility: createAsyncThunk(
    'playlists/toggleCategoryVisibility',
    ({ playlistUUID, groupId, action }) =>
      apiV2.post(`playlists/${playlistUUID}/categories/${groupId}/visibility`, { action }),
  ),
  checkJobStatus: createAsyncThunk(
    'jobs/checkJobStatus', jobID => api.get(`jobs/${jobID}`),
  ),
};

const slice = createSlice({
  name: 'playlists',
  initialState,
  reducers: {
    clearSelectedPlaylist: (state => {
      state.selectedPlaylist = initialState.selectedPlaylist;
    }),
    clearModal: (state => {
      state.error.status = null;
      state.error.errorKey = null;
    }),
    setLoader: (state, { payload }) => {
      state.playlists[payload.uuid].isLoading = payload.isLoading;
    },
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.fetchPlaylists.fulfilled, (state, { payload }) => {
        state.playlists = keyBy(payload, 'uuid');
        state.playlistsKeys = keys(keyBy(payload, 'uuid'));
      })
      .addCase(actions.fetchFavorites.fulfilled, (state, { payload }) => {
        state.favorites = keyBy(payload, 'uuid');
      })
      .addCase(actions.outdatedFetchFullPlaylist.fulfilled, (state, { payload }) => {
        state.selectedPlaylist.channels = keyBy(payload.channels, 'uuid');
        state.selectedPlaylist.categories = payload.groups;
        state.selectedPlaylist.devices = payload.devices;
        state.selectedPlaylist.channelsKeys = getChannelsByKeys(payload.channels);
      })
      .addCase(actions.fetchPlaylistData.fulfilled, (state, { payload }) => {
        state.playlists[payload.uuid].channels_count = payload.channels_count;
        state.selectedPlaylist.devices = payload.devices;
      })
      .addCase(actions.fetchChannels.fulfilled, (state, { payload }) => {
        state.selectedPlaylist.channels = keyBy(payload, 'hash');
        state.selectedPlaylist.channelsKeys = keyBy(
          map(payload, (channel) =>
            pick(channel, [
              'uuid',
              'favorite',
              'hash',
              'hidden',
              'sort_order',
              'playlist_group_id',
            ])
          ),
          'hash'
        );
      })
      .addCase(actions.fetchCategories.fulfilled, (state, { payload }) => {
        state.selectedPlaylist.categories = payload;
      })
      .addCase(actions.outdatedSortChannels.fulfilled, (state, { payload }) => {
        // todo: remove order and fav_order from channels and use it only in channelsKeys
        state.selectedPlaylist.channels = keyBy(payload.channels, 'uuid');
        state.selectedPlaylist.channelsKeys = getChannelsByKeys(payload.channels);
      })
      .addCase(actions.reorderChannels.fulfilled, (state, { payload }) => {
        state.selectedPlaylist.channels = keyBy(payload.channels, 'uuid');
        state.selectedPlaylist.channelsKeys = keyBy(
          map(payload.channels, (channel) =>
            pick(channel, [
              'uuid',
              'favorite',
              'hash',
              'hidden',
              'sort_order',
              'playlist_group_id',
            ])
          ),
          'hash'
        );
      })
      // todo: combine actions here
      .addCase(actions.createPlaylist.pending, (state) => {
        state.error.status = null;
        state.error.errorKey = null;
      })
      .addCase(actions.createPlaylist.fulfilled, (state, { payload }) => {
        state.playlists[payload.uuid] = omit(payload, ['channels']);
        state.playlistsKeys.push(payload.uuid);
        // todo add channels if needed
      })
      .addCase(actions.createPlaylist.rejected, (state, { payload: { status, data } }) => {
        state.error.status = status;
        state.error.errorKey = get(data, 'i18n_key', null);
      })
      .addCase(actions.editPlaylist.pending, (state) => {
        state.error.status = null;
      })
      .addCase(actions.outdatedEditPlaylist.fulfilled, (state, { payload }) => {
        state.playlists[payload.uuid] = omit(payload, ['channels']);
        state.selectedPlaylist.channels = keyBy(payload.channels, 'uuid');
        state.selectedPlaylist.categories = payload.groups;
        state.selectedPlaylist.channelsKeys = getChannelsByKeys(payload.channels);
      })
      .addCase(actions.editPlaylist.fulfilled, (state, { payload }) => {
        state.playlists[payload.uuid] = omit(payload, ['channels']);
        state.selectedPlaylist.devices = get(payload, 'devices', []);
        state.selectedPlaylist.channels = keyBy(payload.channels, 'hash');
        state.selectedPlaylist.channelsKeys = keyBy(
          map(payload.channels, (channel) =>
            pick(channel, [
              'uuid',
              'favorite',
              'hash',
              'hidden',
              'sort_order',
              'playlist_group_id',
            ])
          ),
          'hash'
        );
        state.selectedPlaylist.categories = payload.categories;
      })
      .addCase(actions.changeVisibility.fulfilled, (state, { payload }) => {
        state.playlists[payload.uuid].devices = payload.devices;
        state.selectedPlaylist.devices = payload.devices;
      })
      .addCase(actions.editPlaylist.rejected, (state) => {
        state.error.status = 400;
      })
      .addCase(actions.outdatedSyncPlaylist.fulfilled, (state, { payload }) => {
        state.playlists[payload.uuid] = omit(payload, ['channels']);
      })
      .addCase(actions.syncPlaylist.fulfilled, (state, { payload }) => {
        state.playlists[payload.uuid].job = { status: 'pending', id: payload.job_id };
      })
      .addCase(actions.outdatedSyncPlaylist.rejected, (state, { meta: { arg } }) => {
        state.playlists[arg.uuid].isLoading = false;
        state.error.status = 400;
      })
      .addCase(actions.syncPlaylist.rejected, (state, { meta: { arg } }) => {
        state.playlists[arg.uuid].isLoading = false;
      })
      .addCase(actions.outdatedDeletePlaylist.fulfilled, (state, { meta: { arg } }) => {
        const index = state.playlistsKeys.findIndex((i) => i === arg.uuid);
        state.playlistsKeys.splice(index, 1);
      })
      .addCase(actions.deletePlaylist.fulfilled, (state, { meta: { arg } }) => {
        const index = state.playlistsKeys.findIndex((i) => i === arg.uuid);
        state.playlistsKeys.splice(index, 1);
      })
      .addCase(actions.updateFavorite.fulfilled, (state, { meta: { arg } }) => {
        if (state.selectedPlaylist.channels[arg.channelUUID]) {
          state.selectedPlaylist.channels[arg.channelUUID].favorite = arg.favorite;
        }
        if (arg.favorite === 1) {
          state.favorites[arg.channelUUID] = {};
        } else {
          delete state.favorites[arg.channelUUID];
        }
      })
      .addCase(actions.outdatedUpdateFavorite.fulfilled, (state, { payload }) => {
        if (state.selectedPlaylist.channels[payload.uuid]) {
          state.selectedPlaylist.channels[payload.uuid].favorite = payload.favorite;
        }
        if (payload.favorite) {
          state.favorites[payload.uuid] = payload;
        } else {
          delete state.favorites[payload.uuid];
        }
      })
      .addCase(actions.outdatedHideChannel.pending, (state, { meta: { arg } }) => {
        state.selectedPlaylist.channelsKeys[arg.channelUUID].hidden = !arg.isHidden;
      })
      .addCase(actions.outdatedHideChannel.fulfilled, (state, { payload, meta: { arg } }) => {
        state.selectedPlaylist.channels = keyBy(payload.channels, 'uuid');
        delete state.favorites[arg.channelUUID];
      })
      .addCase(actions.hideChannel.fulfilled, (state, { meta: { arg } }) => {
        state.selectedPlaylist.channelsKeys[arg.channelUUID].hidden = arg.hidden;
        state.selectedPlaylist.channelsKeys[arg.channelUUID].favorite = 0;
        state.selectedPlaylist.channels[arg.channelUUID].hidden = arg.hidden;
        state.selectedPlaylist.channels[arg.channelUUID].favorite = 0;
        delete state.favorites[arg.channelUUID];
      })
      .addCase(actions.hideCategory.fulfilled, (state, { payload }) => {
        state.selectedPlaylist.channelsKeys = keyBy(payload, 'uuid');
        state.selectedPlaylist.channels = keyBy(payload, 'uuid');
      })
      .addCase(actions.toggleCategoryVisibility.fulfilled, (state, { payload }) => {
        state.selectedPlaylist.channelsKeys = keyBy(payload, 'uuid');
        state.selectedPlaylist.channels = keyBy(payload, 'uuid');
      })
      .addCase(actions.checkJobStatus.fulfilled, (state, { payload }) => {
        state.playlists[payload.uuid].job.status = payload.status;
      })
      .addMatcher(
        combineActions(
          actions.outdatedFetchFullPlaylist.pending,
          actions.fetchChannels.pending,
        ),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        combineActions(
          actions.outdatedFetchFullPlaylist.fulfilled,
          actions.outdatedFetchFullPlaylist.rejected,
          actions.fetchPlaylistData.fulfilled,
          actions.fetchPlaylistData.rejected,
          actions.fetchChannels.fulfilled,
          actions.fetchChannels.rejected,
        ),
        (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        combineActions(
          actions.outdatedSyncPlaylist.pending,
          actions.syncPlaylist.pending,
        ),
        (state, { meta: { arg } }) => {
          state.playlists[arg.uuid].isLoading = true;
        }
      )
  },
});

export const playlists = {
  reducer: slice.reducer,
  actions: {
    ...slice.actions,
    ...actions,
  },
  selectors: {
    playlists: createSelector( // please don't use it. READ_ONLY
      state => state.playlists.playlists,
      playlists => playlists,
    ),
    keys: createSelector(
      state => state.playlists.playlistsKeys,
      keys => [...keys].reverse(),
    ),
    makePlaylistSelector: () => createSelector(
      (state, uuid) => state.playlists.playlists[uuid],
      playlist => playlist,
    ),
    makeIsV3PlaylistSelector: () => createSelector(
      (state, uuid) => state.playlists.playlists[uuid],
      (playlist) => !!get(playlist, 'json_path', false),
    ),
    favorites: createSelector(
      state => state.playlists.favorites,
      favorites => toArray(favorites),
    ),
    hasHiddenChannels: createSelector(
      state => state.playlists.selectedPlaylist.channelsKeys,
      channels => toArray(channels).find(({ hidden }) => hidden ),
    ),
    channelsKeys: createSelector(
      state => state.playlists.selectedPlaylist.channelsKeys,
      keys => toArray(keys),
    ),
    makeChannelSelector: () => createSelector(
      (state, uuid) => state.playlists.selectedPlaylist.channels[uuid],
      channel => channel,
    ),
    playlistCategories: createSelector(
      state => state.playlists.selectedPlaylist.categories,
      categories => categories,
    ),
    playlistDevices: createSelector(
      state => state.playlists.selectedPlaylist.devices,
      devices => devices || [],
    ),
    errorStatus: createSelector(
      state => state.playlists.error.status,
      errorStatus => errorStatus,
    ),
    errorKey: createSelector(
      state => state.playlists.error.errorKey,
      errorKey => errorKey,
    ),
    isLoading: createSelector(
      state => state.playlists.isLoading,
      isLoading => isLoading,
    ),
    job: createSelector(
      state => state.playlists.selectedPlaylist.job,
      job => job,
    ),
  }
};
