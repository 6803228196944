import { useParams } from 'react-router-dom';
import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';

import { useDispatch } from 'utils/useDispatch';
import { atoms } from '@recoil/atoms';
import { playlists } from '@redux/playlists';
import { account } from '@redux/account';

import { SidebarItemUI } from './SidebarItemUI';

export const CategoryButton = ({ id, title, hideActions, isHidden = false }) => {
  const { playlistUUID } = useParams();
  const [selectedFilter, setFilter] = useRecoilState(atoms.playlists.selectedFilter);
  const features = useSelector(account.selectors.features);
  const hasV3 = get(features, 'v3', false);

  const toggleCategoryVisibility = useDispatch(playlists.actions.toggleCategoryVisibility);

  // const categoryCount = keys.filter(key => key.playlist_group_id === id && !key.hidden).length;

  // if(categoryCount === 0 ) return null;

  const handleHide = () => {
    hideActions && toggleCategoryVisibility({
      playlistUUID,
      groupId: id,
      action: isHidden ? 'restore' : 'hide',
    });
  };

  const handleClick = () => {
    setFilter(selectedFilter === id ? null : id);
  }

  return (
    <SidebarItemUI
      title={title}
      isSelected={id === selectedFilter}
      handleClick={handleClick}
      handleHide={handleHide}
      canHide={hasV3}
      isHidden={isHidden}
      className={isHidden ? 'text-gray-700' : ''}
    />
  );
}
