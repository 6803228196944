import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { account } from '@redux/account';
import { playlists } from '@redux/playlists';
import { Accordion } from '@ui/Accordion/Accordion';

import { CategoryButton } from './CategoryButton';

export const CategoriesList = () => {
  const { t } = useTranslation();
  const isActiveUser = useSelector(account.selectors.isActiveUser);
  const playlistCategories = useSelector(playlists.selectors.playlistCategories);
  const keys = useSelector(playlists.selectors.channelsKeys);

  if(isEmpty(playlistCategories)) return null;

  const activeCategories = [];
  const hiddenCategories = [];

  playlistCategories.forEach((category) => {
    const categoryCount = keys.filter(key => key.playlist_group_id === category.id && !key.hidden).length;
    if (categoryCount > 0) {
      activeCategories.push(category);
    } else {
      hiddenCategories.push(category);
    }
  });

  return (
    <Accordion
      title={t('playlists.categories')}
      isOpenByDefault
      content={
        activeCategories.map(({ id, title }) => (
          <CategoryButton
            key={id}
            id={id}
            title={title}
            hideActions={isActiveUser}
          />
        ))
      }
      hiddenContent={
        hiddenCategories.map(({ id, title }) => (
          <CategoryButton
            key={id}
            id={id}
            title={title}
            hideActions={isActiveUser}
            isHidden
          />
        ))
      }
    />
  )
}
